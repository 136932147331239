import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import {
  FaPizzaSlice,
  FaGuitar,
  FaDesktop
} from 'react-icons/fa'
const ProgLangList = styled.ul`
  li {
    margin-bottom: 0px;
  }
`

const FaceIcon = styled.div`
  img {
    border: 1px solid gray;
    width: 200px;
    height: 200px;
  }
`

export default () => (
  <Layout>
    <section>
      <FaceIcon>
        <img src="/images/FaceIcon.png" alt="myFace" />
      </FaceIcon>
      <h3>My favorites</h3>
      <p>
        <h4><FaPizzaSlice /> Foods</h4>
        <p>
          <dl>
            <dt>Mozuku-su</dt>
            <dd>With black vinegar</dd>
            <dt>Kikurage (wood ear)</dt>
            <dd>Especially as Stir-fried pork and wood ear eggs</dd>
            <dt>Kan-Buri</dt>
            <dd>Either sashimi or sushi</dd>
            <dt>Kawahagi (filefish)</dt>
            <dd>Starting with sashimi, then miso soup</dd>
          </dl>
        </p>
        <h4><FaGuitar /> Musician</h4>
        <p>
          <dl>
            <dt>Jaco Pastorius (Basist)</dt>
            <dd>His playing Toon Town</dd>
            <dt>Steve Gadd (Drummer)</dt>
            <dd>He is a god for me</dd>
          </dl>
        </p>
      </p>
      <h3>History</h3>
      <p>
        <h4><FaDesktop /> PC history and Programming Language</h4>
        <p>
          <dl>
            <dt>NEC PC8001mkII</dt>
            <dd>
              From my brother (1983 - 1987)<br />
              <ProgLangList>
                <li>Z80 Assembler</li>
              </ProgLangList>
            </dd>
          </dl>
          <dl>
            <dt>Apple Computer Macintosh Plus</dt>
            <dd>
              Actually my first machine (1987 - 1992)<br />
              MPW (Macintosh Programmer's Workshop)
              <ProgLangList>
                <li>MC68000 Assember</li>
                <li>MPW Pascal</li>
              </ProgLangList>
            </dd>
          </dl>
          <dl>
            <dt>NeXT Computer NeXT Cube (MC68040 25MHz 16MB, 400MB HDD)</dt>
            <dd>
              With Laser Printer (1992 - 2002)<br />
              <ProgLangList>
                <li>C</li>
                <li>Perl 5</li>
                <li>Objective-C 1.0</li>
                <li>HyperTalk</li>
                <li>DSP56001 Assembler</li>
                <li>PostScript</li>
              </ProgLangList>
            </dd>
          </dl>
          <dl>
            <dt>Panasonic Let's Note CF-R4</dt>
            <dd>
              My last Windows machine (2005 - 2010)<br />
              <ProgLangList>
                <li>C</li>
                <li>Tcl/Tk</li>
                <li>VBA</li>
                <li>i8085 Assembler</li>
                <li>PIC Assembler</li>
              </ProgLangList>
            </dd>
          </dl>
          <dl>
            <dt>Custom desktop</dt>
            <dd>
              Pentium4 1.4GHz (2002 - 2010)<br />
              <ProgLangList>
                <li>C</li>
                <li>Tcl/Tk</li>
                <li>HTML/CSS/JavaScript</li>
              </ProgLangList>
            </dd>
          </dl>
          <dl>
            <dt>Apple iMac 27' Late 2009</dt>
            <dd>
              Main machine (2010 - 2019)<br />
              Backup machine (2020 - )<br />
              <ProgLangList>
                <li>C</li>
                <li>Objective-C 2.0</li>
                <li>Swift</li>
                <li>Python2/3</li>
                <li>HTML/CSS/JavaScript</li>
                <li>PHP5</li>
              </ProgLangList>
            </dd>
          </dl>
          <dl>
            <dt>Apple MacBook Pro 13' Early 2015</dt>
            <dd>
              Sub machine (2017 - )<br />
              <ProgLangList>
                <li>Objective-C 2.0</li>
                <li>Swift4</li>
                <li>Python2/3</li>
                <li>HTML/CSS/JavaScript</li>
                <li>PHP5</li>
                <li>Prolog</li>
              </ProgLangList>
            </dd>
          </dl>
          <dl>
            <dt>Raspberry Pi Model 3B+</dt>
            <dd>
              Configured by Ansible (2019 - )<br />
              Main Video Server<br />
            </dd>
          </dl>
          <dl>
            <dt>Apple iMac Retina 5K 27' 2019</dt>
            <dd>
              Current main machine (2020 - )<br />
              <ProgLangList>
                <li>Swift5</li>
                <li>Python3</li>
                <li>HTML/CSS/JavaScript</li>
                <li>PHP5/7</li>
              </ProgLangList>
            </dd>
          </dl>
        </p>
      </p>
    </section>
    <section>
      <h4>免責事項</h4>
      <p>
        
      </p>
    </section>
  </Layout>
)
